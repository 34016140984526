import React, { useEffect, useState } from 'react';
import Layout from "../components/layout";
import { Link } from "gatsby";
import Footer from '../components/footer';
import BgImage from '../images/bg/solutions-page-banner.jpg';
import SocialIconsFooter from '../components/social-icons-footer';

const ReportPage = () => {
    const [sheetData, setSheetData] = useState(null);

    useEffect(() => {
        const API_KEY = 'AIzaSyDWGT8TXDen9JPhUqWddESWiE1hXPA_EjE'; // Replace with your actual API Key
        const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];
        const SPREADSHEET_ID = '18ReTTMEAIHjTOE-djdKk1n5BSerY0UVLWE4YOEhkYbg'; // Replace with your spreadsheet ID

        function gapiLoaded() {
            gapi.load('client', initializeGapiClient);
        }

        function initializeGapiClient() {
            gapi.client.init({
                apiKey: API_KEY,
                discoveryDocs: DISCOVERY_DOCS,
            }).then(() => {
                console.log('Google API client initialized.');
            }, (error) => {
                console.error(JSON.stringify(error, null, 2));
            });
        }

        function listMajors(sheetName) {
            gapi.client.sheets.spreadsheets.values.get({
                spreadsheetId: SPREADSHEET_ID,
                range: `${sheetName}!A1:Z`, // Adjust the range to include all columns needed
            }).then((response) => {
                var values = response.result.values;
                if (values.length > 0) {
                    setSheetData(values);
                } else {
                    console.log('No data found.');
                }
            }, (response) => {
                console.error('Error: ' + response.result.error.message);
            });
        }

        window.gapiLoaded = gapiLoaded;
        window.listMajors = listMajors;

        const script = document.createElement('script');
        script.src = "https://apis.google.com/js/api.js";
        script.onload = () => {
            gapiLoaded();
        };
        document.body.appendChild(script);
    }, []);

    const renderTable = (data) => {
        if (!data) return null;

        return (
            <table>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            rowIndex === 0 ? (
                                <th key={cellIndex}>{cell}</th>
                            ) : (
                                <td key={cellIndex}>{cell}</td>
                            )
                        ))}
                    </tr>
                ))}
            </table>
        );
    };

    return (
        <Layout pageTitle="PragICTS | Worksite Report" og_desc="Support services by PragICTS">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>PragICTS Worksite<br/><span> Report</span></h2>
                                <p></p>
                                <div className="horizonral-subtitle"><span>REPORT</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>report</div>
                        <div className="newww">
                            <button onClick={() => window.listMajors('sample')}>Load Sample Sheet</button>
                            <button onClick={() => window.listMajors('sample2')}>Load Sample2 Sheet</button>
                            <div id="sheet-table">
                                {renderTable(sheetData)}
                            </div>
                        </div>

                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
            <style jsx>{`
                body {
                    font-family: Arial, sans-serif;
                }
                table {
                    border-collapse: collapse;
                    width: 100%;
                    margin-top: 20px;
                }
                table, th, td {
                    border: 1px solid red;
                }
                th, td {
                    padding: 8px;
                    text-align: left;
                }
                th {
                    background-color: #f2f2f2;
                }
                tr:nth-child(even) {
                    background-color: #f9f9f9;
                }
                tr:hover {
                    background-color: #f1f1f1;
                }
                button {
                    background-color: red;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 16px;
                    margin: 4px 2px;
                    cursor: pointer;
                }
                button:hover {
                    background-color: darkred;
                }
            `}</style>
        </Layout>
    );
};

export default ReportPage;
